import React, { useEffect, useState } from 'react'
import { useWizard, Wizard } from 'react-use-wizard'
import Select from 'react-select'
import axios from 'axios'
import { API_URL, jsonHeader, multiFormHeader } from '../../../Constants/global'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'

function AddProperty ({ propertyData }) {
  const [Editdata, setEditdata] = useState([])
  const [front_Image_file, setfront_Image_file] = useState()
  const [propertySelectedAmunities, setpropertySelectedAmunities] = useState([])
  const [title_error, setTitle_error] = useState('')
  const [isLoadingProperties, setIsLoadingProperties] = useState({
    isLoading: false,
    disabled: false
  })
  const [isLoadingAmenities, setIsLoadingAmenities] = useState({
    isLoading: false,
    disabled: false
  })
  const [isLoadingImages, setIsLoadingImages] = useState({
    isLoading: false,
    disabled: false
  })

  const project_params = useParams()

  // ADD PROPERTY CONST

  const [details, setDetails] = useState({
    id: 0,
    city_id: 0,
    area_id: 0,
    property_purpose: 1,
    property_group_type: 1,
    property_type: 0,
    title: '',
    description: '',
    zipcode: '',
    address: '',
    landarea: '',
    areaunit: 0,
    price: 0,
    currency: 'PKR',
    old_file: '',
    front_image: ''
  })

  const [cityList, setcityList] = useState([])
  const [areaList, setareaList] = useState([])
  const [propertyType, setpropertyType] = useState([])
  const [propertyTypeGroup, setpropertyTypeGroup] = useState([])
  const [AmenitiesList, setAmenitiesList] = useState([])
  const [ImagesArray, setImagesArray] = useState([])
  const [areaUnitList, setareaUnitList] = useState([])
  const [propertyID, setpropertyID] = useState(0)

  //  form Errors

  const [property_type_error, setProperty_type_error] = useState('')
  const [city_list_error, setCity_list_error] = useState('')
  const [area_list_error, setArea_list_error] = useState('')

  const [tabStep, settabStep] = useState(0)

  const [userAmenitiesList, setuserAmenitiesList] = useState([])

  var AmenitiesSelected = []

  useEffect(() => {
    if (project_params.id !== undefined) {
      axios({
        method: 'GET',
        url: API_URL + 'api/properties/' + project_params.id,

        headers: jsonHeader
      }).then(response => {
        if (response.status === 200) {
          setDetails({
            id: parseInt(response.data?.data.id),
            city_id: parseInt(response.data?.data.city_id),
            area_id: parseInt(response.data?.data.area_id),
            property_purpose: parseInt(response.data?.data.property_purpose_id),
            property_group_type: parseInt(
              response.data?.data.property_type_data[0].group_id
            ),
            property_type: parseInt(response.data?.data.property_type_id),
            title: response.data?.data.title,
            description: response.data?.data.description,
            zipcode: response.data?.data.zipcode,
            address: response.data?.data.address,
            landarea: parseInt(response.data?.data.land_area),
            areaunit: response.data?.data.area_unit,
            price: parseInt(response.data?.data.price),
            currency: response.data?.data.currency,
            front_image: response.data?.data.front_image,

            old_file: response.data?.data.front_image
          })

          setpropertySelectedAmunities(response.data?.data.amenities)
          console.log(`-----------------------------${details}`)
          console.log('amunities  ' + response.data?.data.amenities)

          fetch(
            API_URL +
              'generalservices/areabycityid/' +
              response.data?.data.city_id
          )
            .then(response => response.json())
            .then(data => {
              if (data.status === 200) {
                const nArray = []
                data.data.map(item => {
                  let roleData = {}
                  roleData.value = item.id
                  roleData.label = item.area
                  nArray.push(roleData)
                })
                setareaList(nArray)
              }
            })
        }
      })
    }

    async function fetchCountries () {
      const response = await axios({
        method: 'GET',
        url: API_URL + 'generalservices/citybycountryid/1',
        headers: jsonHeader
      })

      if (response.status === 200) {
        const nArray = []
        response.data?.data.map(item => {
          let roleData = {}
          roleData.value = item.id
          roleData.label = item.city
          nArray.push(roleData)
        })
        setcityList(nArray)
      }

      const response_type_group = await axios({
        method: 'GET',
        url: API_URL + 'api/property_type_by_group',
        headers: jsonHeader
      })

      const nArray_group = []
      const nArray = []
      response_type_group.data?.data.map(item => {
        let roleData = {}
        roleData.label = item.title
        roleData.value = item.id
        nArray_group.push(roleData)
      })

      setpropertyTypeGroup(nArray_group)

      console.log(propertyType)

      // /generalservices/amenities

      const amenitiesList = await axios({
        method: 'POST',
        url: API_URL + 'generalservices/amenities_rectjs',
        data: {
          id: project_params.id !== undefined ? project_params.id : 0,
          showAll: false,
          isActive: 1,
          isDeleted: 0
        },
        headers: jsonHeader
      })

      setAmenitiesList(amenitiesList.data?.data)
      if (project_params.id !== undefined) {
        const imagesList = await axios({
          method: 'GET',
          url: API_URL + 'api/get_property_images/' + project_params.id,
          headers: jsonHeader
        })

        setImagesArray(imagesList.data?.data)
      }

      const areaUnitList = await axios({
        method: 'POST',
        url: API_URL + 'metadata/property_area_units',
        data: { id: 0, showAll: true, isActive: 0, isDeleted: 0 },
        headers: jsonHeader
      })

      areaUnitList.data?.data.map(item => {
        let roleData = {}
        roleData.value = item.id
        roleData.label = item.title
        nArray.push(roleData)
      })
      setareaUnitList(nArray)
    }

    fetchCountries()

    // fecth property type

    async function fetchPropertyType () {
      const response = await axios({
        method: 'GET',
        url:
          API_URL + 'api/property_type_by_groupid/' + details.property_purpose,
        headers: jsonHeader
      })

      if (response.status === 200) {
        const nArray = []
        response.data?.data.map(item => {
          let roleData = {}
          roleData.value = item.id
          roleData.label = item.title
          nArray.push(roleData)
        })
        setpropertyType(nArray)
      }
    }

    fetchPropertyType()
  }, [])

  async function fetchAreasbycityID (value) {
    setDetails({ ...details, city_id: value.value })

    const response = await axios({
      method: 'GET',
      url: API_URL + 'generalservices/areabycityid/' + value.value,
      headers: jsonHeader
    })

    if (response.status === 200) {
      const nArray = []
      response.data?.data.map(item => {
        let roleData = {}
        roleData.value = item.id
        roleData.label = item.area
        nArray.push(roleData)
      })
      setareaList(nArray)
    }
  }

  function onAreaChange (value) {
    setDetails({ ...details, area_id: value.value })
  }

  async function onPropertyType (value) {
    console.log(value)
    const response = await axios({
      method: 'GET',
      url: API_URL + 'api/property_type_by_groupid/' + value.value,
      headers: jsonHeader
    })

    if (response.status === 200) {
      const nArray = []
      response.data?.data.map(item => {
        let roleData = {}
        roleData.value = item.id
        roleData.label = item.title
        nArray.push(roleData)
      })
      setpropertyType(nArray)
    }

    setDetails({ ...details, property_group_type: value.value })
  }

  function update_setuserAmenitiesList (e) {
    console.log(e.target.alt)
    var curarray = AmenitiesSelected.find(
      x => x.amenity_id === parseInt(e.target.id)
    )

    if (curarray) {
      if (e.target.alt === 'Boolean') {
        replaceData(e.target.checked ? true : false, parseInt(e.target.id))
      }
      if (e.target.alt === 'text') {
        replaceData(e.target.value, parseInt(e.target.id))
      }

      if (e.target.alt === 'number') {
        replaceData(parseInt(e.target.value), parseInt(e.target.id))
      }
    } else {
      if (e.target.alt === 'text') {
        const userData = {
          property_id: propertyID,
          amenity_id: parseInt(e.target.id),
          amenity_value: e.target.value
        }
        AmenitiesSelected.push(userData)
      }

      if (e.target.alt === 'number') {
        const userData = {
          property_id: propertyID,
          amenity_id: parseInt(e.target.id),
          amenity_value: parseInt(e.target.value)
        }
        AmenitiesSelected.push(userData)
      }

      if (e.target.alt === 'Boolean') {
        const userData = {
          property_id: propertyID,
          amenity_id: parseInt(e.target.id),
          amenity_value: e.target.checked ? true : false
        }
        AmenitiesSelected.push(userData)
      }
    }
    console.log(e)
    console.log(AmenitiesSelected)
  }

  function replaceData (value, amenity_id) {
    AmenitiesSelected.forEach(element => {
      if (element.amenity_id === amenity_id) {
        element.amenity_value = value
      }
    })
  }

  console.log(details)
  console.log(localStorage.getItem('token'))
  // ADD PROPERTY CONST

  const Step1 = () => {
    const { handleStep, previousStep, nextStep, goToStep } = useWizard()

    const propertyPurposeHandler = event => {
      console.log(event.target.value)
      setDetails({
        ...details,
        property_purpose: parseInt(event.target.value)
      })
    }

    const checkFormData1 = values => {
      //   switch (details) {
      //     case details.property_type === 0: {
      //       setProperty_type_error("Please select property type.");
      //     }
      //     case details.city_id === 0: {
      //       setCity_list_error("Please select city.");
      //     }
      //     case details.area_id === 0: {
      //       setArea_list_error("Please select area.");
      //     }
      //     default: {
      //       goToStep(1);
      //     }
      //   }
      // };
      if (
        details.property_type === 0 ||
        details.city_id === 0 ||
        details.area_id === 0
      ) {
        if (details.property_type === 0) {
          setProperty_type_error('Please select property category.')
        } else {
          setProperty_type_error('')
        }
        if (details.city_id === 0) {
          setCity_list_error('Please select city.')
        } else {
          setCity_list_error('')
        }
        if (details.area_id === 0) {
          setArea_list_error('Please select area.')
        } else {
          setArea_list_error('')
        }
      } else {
        goToStep(1)
      }
    }

    return (
      <>
        <section className='add-listing-section space-ptb'>
          <div className='container'>
            <div className='add-listing-steps'>
              <ul
                className='nav nav-tabs nav-tabs-03 nav-fill'
                id='myTab'
                role='tablist'
              >
                <li className='nav-item'>
                  <a className='nav-link active'>
                    <span>01</span>
                    Listing Information
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link'>
                    <span>02</span>
                    Property Details
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link'>
                    <span>03</span>
                    Amenities
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link'>
                    <span>04</span>
                    Property Images
                  </a>
                </li>
              </ul>
            </div>

            <div className='add-listing-main mt-4'>
              <form>
                <div className='form-row'>
                  <div className='col-md-12 p-0'>
                    <div className='form-group'>
                      <label className='mb-2'>Property Purpose :</label>
                      <div className='kt-radio-inline radio-inline--v2'>
                        <label className='kt-radio radio--v2'>
                          <input
                            type='radio'
                            name='property_purpose'
                            value={1}
                            id='property_purpose-0'
                            defaultChecked
                            checked={details.property_purpose === 1}
                            onChange={propertyPurposeHandler.bind(this)}
                          />
                          For Sale
                          <span />
                        </label>

                        <label className='kt-radio radio--v2'>
                          <input
                            type='radio'
                            name='property_purpose'
                            value={2}
                            id='property_purpose-1'
                            checked={details.property_purpose === 2}
                            onChange={propertyPurposeHandler.bind(this)}
                          />
                          For Rent
                          <span />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='form-group col-md-12 select-border'>
                    <label>Property Category *</label>

                    <Select
                      className='form-control basic-select'
                      //   isLoading={isloading}
                      options={propertyTypeGroup}
                      placeholder='Property Group Type'
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={onPropertyType}
                      value={propertyTypeGroup.find(
                        c => c.value === parseInt(details.property_group_type)
                      )}
                    />

                    <Select
                      className='form-control basic-select mt-2'
                      //   isLoading={isloading}
                      options={propertyType}
                      placeholder='Property Category'
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={e =>
                        setDetails({
                          ...details,
                          property_type: parseInt(e.value)
                        })
                      }
                      value={propertyType.find(
                        c => c.value === parseInt(details.property_type)
                      )}
                    />
                    {property_type_error ? (
                      <div className='error text-danger'>
                        {' '}
                        {property_type_error}{' '}
                      </div>
                    ) : null}
                  </div>

                  <div className='form-group col-md-12'>
                    <label>City *</label>

                    <Select
                      className='form-control basic-select'
                      //   isLoading={isloading}
                      options={cityList}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={fetchAreasbycityID}
                      value={cityList.find(
                        c => c.value === parseInt(details.city_id)
                      )}
                    />
                    {city_list_error ? (
                      <div className='error text-danger'>
                        {' '}
                        {city_list_error}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group col-md-12 select-border'>
                    <label>Area *</label>

                    <Select
                      className='form-control basic-select'
                      //   isLoading={isloading}
                      options={areaList}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={onAreaChange}
                      value={areaList.find(
                        c => c.value === parseInt(details.area_id)
                      )}
                    />
                    {area_list_error ? (
                      <div className='error text-danger'>
                        {' '}
                        {area_list_error}{' '}
                      </div>
                    ) : null}
                  </div>
                </div>
                <hr className='mt-2 mb-4'></hr>
              </form>
            </div>
            <div className='w-100 d-flex justify-content-between align-items-center'>
              <button
                className='btn btn-primary btn-md nav-prev'
                onClick={() => previousStep()}
              >
                Previous
              </button>
              <button
                className='btn btn-primary btn-md nav-next'
                onClick={checkFormData1}
              >
                Next
              </button>
            </div>
          </div>
        </section>
      </>
    )
  }
  const Step2 = () => {
    const [img, setImg] = useState()
    const [imageError, setImageError] = useState('')
    const { handleStep, previousStep, nextStep, goToStep } = useWizard()
    const [areaunit, setAreaunit] = useState(0)
    const [frontImagefile, setfrontImagefile] = useState()
    const [frontImageError, setfrontImageError] = useState('')

    const onSubmitProperty = values => {
      if (imageError === '') {
        if (project_params.id === undefined) {
          if (frontImagefile === undefined || frontImagefile === '') {
            setfrontImageError('Front image is required.')
            return
          }
        }

        setfrontImageError('')
        setIsLoadingProperties({
          isLoading: true,
          disabled: true
        })
        var form = new FormData()
        form.append('id', values.id)
        form.append('city_id', values.city_id)
        form.append('area_id', values.area_id)
        form.append('property_purpose_id', values.property_purpose)
        form.append('property_type_id', values.property_type)
        form.append('title', values.title)
        form.append('description', values.description)
        form.append('zipcode', values.zipcode)
        form.append('address', values.address)
        form.append('land_area', values.landarea)
        form.append('area_unit', areaunit)
        form.append('price', values.price)
        form.append('currency', values.currency)
        form.append('rent_frequency', '')
        form.append('country_id', 1)
        form.append('agency_id', 0)
        form.append('active', 0)
        form.append('is_wanted', 0)
        form.append('is_hot', 0)
        form.append('is_featured', 0)
        form.append('is_promo', 0)
        form.append('is_privacy', 0)
        form.append('property_status_id', 0)
        form.append('is_popout', 0)
        form.append(
          'front_image',
          frontImagefile ? frontImagefile.target.files[0] : ''
        )
        form.append('old_file', details.old_file)
        form.append('front_video', '')
        form.append('location', values.address)
        form.append('images_qty', 0)
        form.append('amenities', JSON.stringify(AmenitiesSelected, null, 2))
        form.append('user_id', parseInt(localStorage.getItem('user_id')))

        if (frontImagefile !== undefined) {
          setfront_Image_file(frontImagefile.target.files[0])
        }

        axios({
          method: 'POST',
          url: API_URL + 'api/property_add',
          data: form,
          headers: multiFormHeader
        }).then(response => {
          if (response.data.status === 200) {
            setIsLoadingProperties({
              isLoading: false,
              disabled: false
            })
            setpropertyID(response.data?.property_id)
            console.log(propertyID)
            goToStep(2)
          } else {
            // alert(response.data?.message);
            setTitle_error('Title already exists')

            setIsLoadingProperties({
              isLoading: false,
              disabled: false
            })
          }
        })
      }
    }

    const validateSchema = Yup.object().shape({
      title: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
      zipcode: Yup.string()
        // .required("Required")
        .max(8, 'Must not be more than 8 characters'),
      address: Yup.string().required('Required'),
      landarea: Yup.number()
        .required('Required')
        .min(1, 'Min value 1.')
        .max(10000000, 'Max value 10000000.'),
      price: Yup.number()
        .required('Required')
        .min(1, 'Min value 1.')
        .max(1000000000, 'Max value 1000000000.')
    })

    const formik = useFormik({
      initialValues: details,
      onSubmit: onSubmitProperty,
      validationSchema: validateSchema,
      enableReinitialize: true
    })

    const handleFrontImageChange = e => {
      if (e.target.files[0].size < 500000) {
        setfrontImagefile(e)
        const [file] = e.target.files
        setImg(URL.createObjectURL(file))
        setImageError('')
      } else {
        setImageError('File size should be less than 500 kb')
        setImg('')
      }
    }

    return (
      <>
        <section className='add-listing-section space-ptb'>
          <div className='container'>
            <div className='add-listing-steps'>
              <ul
                className='nav nav-tabs nav-tabs-03 nav-fill'
                id='myTab'
                role='tablist'
              >
                <li className='nav-item'>
                  <a className='nav-link '>
                    <span>01</span>
                    Listing Information
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link active'>
                    <span>02</span>
                    Property Details
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link'>
                    <span>03</span>
                    Amenities
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link'>
                    <span>04</span>
                    Property Images
                  </a>
                </li>
              </ul>
            </div>

            <form onSubmit={formik.handleSubmit} autoComplete='off'>
              <div className='add-listing-main mt-4'>
                <div className='row'>
                  <div className='form-group col-md-6 select-border'>
                    <label>Title</label>

                    <input
                      type='text'
                      id='title'
                      name='title'
                      className='form-control'
                      placeholder='Title'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.title}{' '}
                      </div>
                    ) : null}
                    {<div className='error text-danger'> {title_error} </div>}
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-6 field-description'>
                    <textarea
                      className='form-control floating-textarea '
                      name='description'
                      rows={4}
                      placeholder='Description *'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.description}{' '}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-3 select-border'>
                    <label>Zip Code</label>
                    <input
                      name='zipcode'
                      type='text'
                      className='form-control'
                      placeholder='Zip Code'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.zipcode}
                    />
                    {formik.touched.zipcode && formik.errors.zipcode ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.zipcode}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group col-md-3 select-border'>
                    <label>Address</label>
                    <input
                      type='text'
                      name='address'
                      className='form-control'
                      placeholder='Address'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.address}{' '}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-3 select-border'>
                    <label>Land Area</label>
                    <input
                      type='text'
                      name='landarea'
                      className='form-control'
                      placeholder='Land Area'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.landarea}
                    />
                    {formik.touched.landarea && formik.errors.landarea ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.landarea}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group col-md-3 select-border'>
                    <label>Area Unit</label>
                    <Select
                      className='form-control basic-select'
                      //   isLoading={isloading}
                      options={areaUnitList}
                      isMulti={false}
                      name='areaunit'
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      placeholder='sq feet'
                      onChange={e => setAreaunit(parseInt(e.value))}
                      value={areaUnitList.find(
                        c => c.value === parseInt(areaunit)
                      )}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col-md-3 select-border'>
                    <label>Price</label>
                    <input
                      type='text'
                      name='price'
                      className='form-control'
                      placeholder='Price'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.price}
                    />
                    {formik.touched.price && formik.errors.price ? (
                      <div className='error text-danger'>
                        {' '}
                        {formik.errors.price}{' '}
                      </div>
                    ) : null}
                  </div>
                  <div className='form-group col-md-3 select-border'>
                    <label>Currency</label>
                    <select className='form-control basic-select'>
                      <option value='value 01' selected='selected'>
                        PKR
                      </option>
                    </select>
                  </div>
                </div>

                <div className='col-md-12 p-0 image-tag-heading'>
                  <h4>
                    Front Image <span className='red'>*</span>
                  </h4>
                  <h6>Image Size: (400*300)</h6>
                </div>
                <div className='col-md-12 p-0 mb-3'>
                  <div className='upload-property-imgs-outer'>
                    <div className='upload-single-img-listing field-front_image'>
                      <input
                        type='file'
                        accept='image/*'
                        onChange={handleFrontImageChange}
                        id='front_image'
                        className=''
                        name='front_image'
                      />
                    </div>
                  </div>
                  {details.front_image ? (
                    <div className='multi-previewMain mt-2'>
                      <img src={details.front_image} alt='' />{' '}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='col-md-12 p-0 image-tag-heading'>
                    <h6>Allowed file types: png, jpg, jpeg.</h6>
                  </div>
                  <span className='text-danger'>{frontImageError}</span>
                  <p className='text-danger'>{imageError}</p>
                </div>
                {img ? (
                  <div className='multi-previewMain'>
                    <img src={img} alt='' />{' '}
                  </div>
                ) : (
                  ''
                )}
                <hr className='mt-2 mb-4'></hr>

                <div className='w-100 d-flex justify-content-between align-items-center'>
                  <button
                    type='button'
                    className='btn btn-primary btn-md nav-prev'
                    onClick={() => previousStep()}
                  >
                    Previous
                  </button>
                  <button
                    disabled={isLoadingProperties.disabled}
                    type='submit'
                    className='btn btn-primary btn-md nav-next'
                  >
                    {project_params.id !== undefined
                      ? 'Update Property'
                      : 'Create Property'}
                    <span
                      hidden={isLoadingProperties.disabled - 1}
                      className='spinner-border spinner-border-sm ml-2'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </>
    )
  }
  const Step3 = () => {
    const { handleStep, previousStep, nextStep, goToStep } = useWizard()

    function onSubmitAmenities () {
      setIsLoadingAmenities({
        isLoading: true,
        disabled: true
      })
      var form = new FormData()
      form.append('id', propertyID)
      form.append('amenities', JSON.stringify(AmenitiesSelected, null, 2))

      axios({
        method: 'POST',
        url: API_URL + 'api/amenities_add',
        data: form,
        headers: multiFormHeader
      }).then(response => {
        if (response.data.status === 200) {
          setIsLoadingAmenities({
            isLoading: false,
            disabled: false
          })
          goToStep(3)
        } else {
          setIsLoadingAmenities({
            isLoading: false,
            disabled: false
          })
        }
      })
    }

    return (
      <>
        <section className='add-listing-section space-ptb'>
          <div className='container'>
            <div className='add-listing-steps'>
              <ul
                className='nav nav-tabs nav-tabs-03 nav-fill'
                id='myTab'
                role='tablist'
              >
                <li className='nav-item'>
                  <a className='nav-link '>
                    <span>01</span>
                    Listing Information
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link '>
                    <span>02</span>
                    Property Details
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link active'>
                    <span>03</span>
                    Amenities
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link'>
                    <span>04</span>
                    Property Images
                  </a>
                </li>
              </ul>
            </div>
            <div className='add-listing-main mt-4'>
              <div className='row'>
                <div className='col-md-12'>
                  <h1 className='list-property-amenities'>Amenities</h1>
                  <hr />
                </div>
                <div className='col-md-12'>
                  <div className='accordion-style-2' id='accordion-02'>
                    {AmenitiesList.map((item, index) => {
                      return (
                        <div className='card'>
                          <div className='card-header' id>
                            <h5 className='accordion-title mb-0'>
                              <button
                                type='button'
                                className='btn btn-link d-flex ml-auto align-items-center collapsed'
                                data-toggle='collapse'
                                data-target={`#collapse-${index}`}
                                aria-expanded='false'
                                aria-controls='collapsefour'
                              >
                                {item.amenity_group}
                                <i className='fas fa-chevron-down fa-xs' />
                              </button>
                            </h5>
                          </div>
                          <div
                            id={`collapse-${index}`}
                            className='accordion-content collapse'
                            aria-labelledby='headingfour'
                            data-parent='#accordion-02'
                            style={{}}
                          >
                            <div className='card-body d-flex flex-wrap'>
                              {item.amunities_childs.map(child => {
                                if (child.input_type === 'text') {
                                  if (child.selectedValue !== '') {
                                    const userData = {
                                      property_id: propertyID,
                                      amenity_id: parseInt(child.id),
                                      amenity_value: child.selectedValue
                                    }
                                    AmenitiesSelected.push(userData)

                                    return (
                                      <div className='form-group col-md-4 select-border'>
                                        <label>{child.amenity}</label>

                                        <input
                                          type='text'
                                          className='form-control'
                                          name={child.amenity}
                                          placeholder={child.amenity}
                                          id={child.id}
                                          alt={child.input_type}
                                          onChange={update_setuserAmenitiesList}
                                          value={child.selectedValue}
                                        />
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <div className='form-group col-md-4 select-border'>
                                        <label>{child.amenity}</label>

                                        <input
                                          type='text'
                                          className='form-control'
                                          name={child.amenity}
                                          placeholder={child.amenity}
                                          id={child.id}
                                          alt={child.input_type}
                                          onChange={update_setuserAmenitiesList}
                                        />
                                      </div>
                                    )
                                  }
                                }

                                if (child.input_type === 'Boolean') {
                                  if (child.selectedValue !== '') {
                                    const userData = {
                                      property_id: propertyID,
                                      amenity_id: parseInt(child.id),
                                      amenity_value: child.selectedValue
                                    }
                                    AmenitiesSelected.push(userData)
                                    return (
                                      <div className='form-group col-md-4 select-border'>
                                        <div className='custom-control custom-checkbox d-flex'>
                                          <input
                                            className='custom-control-input'
                                            type='checkbox'
                                            name={child.amenity}
                                            alt={child.input_type}
                                            id={child.id}
                                            onClick={
                                              update_setuserAmenitiesList
                                            }
                                            defaultChecked={child.selectedValue}
                                          />
                                          <label
                                            className='custom-control-label'
                                            htmlFor={child.id}
                                          >
                                            {child.amenity}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <div className='form-group col-md-4 select-border'>
                                        <div className='custom-control custom-checkbox d-flex'>
                                          <input
                                            className='custom-control-input'
                                            type='checkbox'
                                            name={child.amenity}
                                            alt={child.input_type}
                                            id={child.id}
                                            onClick={
                                              update_setuserAmenitiesList
                                            }
                                          />
                                          <label
                                            className='custom-control-label'
                                            htmlFor={child.id}
                                          >
                                            {child.amenity}
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  }
                                }

                                if (child.input_type === 'number') {
                                  if (child.selectedValue !== '') {
                                    const userData = {
                                      property_id: propertyID,
                                      amenity_id: parseInt(child.id),
                                      amenity_value: child.selectedValue
                                    }
                                    AmenitiesSelected.push(userData)
                                    return (
                                      <div className='form-group col-md-4 select-border'>
                                        <label>{child.amenity}</label>
                                        <input
                                          type='text'
                                          name={child.amenity}
                                          className='form-control'
                                          placeholder={child.amenity}
                                          id={child.id}
                                          alt={child.input_type}
                                          onChange={update_setuserAmenitiesList}
                                          value={child.selectedValue}
                                        />
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <div className='form-group col-md-4 select-border'>
                                        <label>{child.amenity}</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          name={child.amenity}
                                          placeholder={child.amenity}
                                          id={child.id}
                                          alt={child.input_type}
                                          onChange={update_setuserAmenitiesList}
                                        />
                                      </div>
                                    )
                                  }
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              <hr className='mt-2 mb-4'></hr>

              <div className='w-100 d-flex justify-content-between align-items-center'>
                <button
                  disabled={isLoadingAmenities.disabled}
                  className='btn btn-primary btn-md nav-next'
                  onClick={onSubmitAmenities}
                >
                  Next
                  <span
                    hidden={isLoadingAmenities.disabled - 1}
                    className='spinner-border spinner-border-sm ml-2'
                    role='status'
                    aria-hidden='true'
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
  const Step4 = () => {
    const [imageError, setImageError] = useState('')
    const { handleStep, previousStep, nextStep, goToStep } = useWizard()
    const [selectedfile, setSelectedFile] = useState([])
    const [totalImages, settotalImages] = useState(0)
    const [selectedfileArray, setselectedfileArray] = useState([])
    const [fileSizes, setFileSizes] = useState([])

    const fileObj = []
    const fileArray = []
    const fileSizesArray = []

    const handleChange = e => {
      if (e.target.files.length < 11) {
        setselectedfileArray([])
        setFileSizes([])
        setImageError('')
        for (let i = 0; i < e.target.files.length; i++) {
          if (e.target.files[i].size > 500000) {
            // alert("");
            setImageError('File size should be less than 500 kb')
          }
        }
        console.log(e.target.files)
        if (imageError === '') {
          // setImageError("");
          setSelectedFile(e)
          fileObj.push(e.target.files)
          for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push(URL.createObjectURL(fileObj[0][i]))
            fileSizesArray.push(fileObj[0][i].size)
            // console.log(fileObj);
          }
          setselectedfileArray(fileArray)
          setFileSizes(fileSizesArray)
          console.log(fileSizesArray)
        }
      } else {
        setImageError('only 10 images can be selected in total')
      }
    }

    function onSubmitImages () {
      if (imageError === '') {
        //   window.location.replace("/my-properties");

        if (selectedfile.length === 0) {
          setIsLoadingImages({
            isLoading: false,
            disabled: false
          })

          window.location.href = '/my-properties'
          return
        }

        setIsLoadingImages({
          isLoading: true,
          disabled: true
        })

        var form = new FormData()
        //if front image is provided
        if (front_Image_file !== '') {
          form.append('images-' + 0, front_Image_file)
          for (var i = 1, element; (element = selectedfile.target.files[i]); ) {
            console.log(element)
            form.append('images-' + i, element)
            i++
            settotalImages(i)
          }

          settotalImages(selectedfile.target.files.length + 1)
          form.append('images_qty', selectedfile.target.files.length + 1)
        } else {
          for (var i = 0, element; (element = selectedfile.target.files[i]); ) {
            console.log(element)
            form.append('images-' + i, element)
            i++
            settotalImages(i)
          }

          settotalImages(selectedfile.target.files.length)
          form.append('images_qty', selectedfile.target.files.length)
        }

        form.append('id', details.id)
        form.append('property_id', propertyID)

        console.log(form.values)

        axios({
          method: 'POST',
          url: API_URL + 'api/property_images',
          data: form,
          headers: multiFormHeader
        }).then(response => {
          if (response.status === 200) {
            setIsLoadingImages({
              isLoading: false,
              disabled: false
            })

            window.location.href = '/my-properties'
          } else {
            setIsLoadingImages({
              isLoading: false,
              disabled: false
            })
          }
        })
      }
    }

    const deleteArray = e => {
      const file_url = e.target.name
      const type = e.target.id

      if (type === 'remote_images') {
        console.log(file_url)

        if (file_url !== undefined) {
          const response = axios({
            method: 'POST',
            url: API_URL + 'generalservices/delete_record_str',
            data: {
              table_name: 'joproperty_images',
              field_id: 'image_url',
              field_value: file_url
            },
            headers: jsonHeader
          })
          if (response.status === 200) {
            for (var i = 0; i < ImagesArray.length; i++) {
              if (ImagesArray[i].image_url === file_url) {
                ImagesArray.splice(i, 1)
                break
              }
            }
          }
        }
      } else {
        for (var z = 0; z < selectedfileArray.length; z++) {
          if (selectedfileArray[z] === file_url) {
            selectedfileArray.splice(z, 1)
            break
          }
        }
      }
    }

    return (
      <>
        <section className='add-listing-section space-ptb'>
          <div className='container'>
            <div className='add-listing-steps'>
              <ul
                className='nav nav-tabs nav-tabs-03 nav-fill'
                id='myTab'
                role='tablist'
              >
                <li className='nav-item'>
                  <a className='nav-link '>
                    <span>01</span>
                    Listing Information
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link '>
                    <span>02</span>
                    Property Details
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link'>
                    <span>03</span>
                    Amenities
                  </a>
                </li>
                <li className='nav-item '>
                  <a className='nav-link active'>
                    <span>04</span>
                    Property Images
                  </a>
                </li>
              </ul>
            </div>
            <div className='add-listing-main mt-4'>
              <form>
                <div className='submit-property--outer-part'>
                  <div className='col-md-12 p-0 image-tag-heading'>
                    <h4>
                      Additional Image <span className='red'>*</span>
                    </h4>
                    <h6>Image Size: (400*300)</h6>
                  </div>
                  <div className='col-md-12 p-0 mb-3'>
                    <div className='upload-property-imgs-outer'>
                      <div className='upload-single-img-listing field-front_image'>
                        <input
                          type='file'
                          multiple
                          onChange={handleChange}
                          id='front_image'
                          className=''
                          accept='image/*'
                          name='front_image'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12 p-0 image-tag-heading'>
                    <h6>Allowed file types: png, jpg, jpeg.</h6>
                  </div>

                  <div className='form-group multi-preview'>
                    <div className='multi-preview-inner'>
                      {selectedfileArray.map((url, index) => {
                        return (
                          <div
                            className={`multi-previewMain ${
                              Math.round(parseInt(fileSizes[index]) * 0.001) >
                              500
                                ? 'bg-danger'
                                : ''
                            }`}
                          >
                            <img src={url} alt='...' />
                            <p
                              className={
                                Math.round(parseInt(fileSizes[index]) * 0.001) >
                                500
                                  ? 'text-danger'
                                  : ''
                              }
                            >
                              {Math.round(parseInt(fileSizes[index]) * 0.001)}{' '}
                              kb
                            </p>
                            {/* <button type="button" className="fa fa-trash" name={url} id="local_images" onClick={deleteArray}></button> */}
                          </div>
                        )
                      })}
                      {(ImagesArray || []).map(url => {
                        return (
                          <div className='multi-previewMain-del'>
                            <img src={url.image_url} alt='...' />
                            <button
                              type='button'
                              className='btn btn-danger px-3 p-2 my-2'
                              name={url.image_url}
                              id='remote_images'
                              onClick={deleteArray}
                            >
                              <i className='fas fa-trash p-0'></i>
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <p className='text-danger'>{imageError}</p>
                </div>

                <hr className='mt-4 mb-4'></hr>

                <div className='w-100 d-flex justify-content-between align-items-center'>
                  <button
                    className='btn btn-primary btn-md nav-prev'
                    onClick={() => previousStep()}
                  >
                    Previous
                  </button>
                  <button
                    disabled={isLoadingImages.disabled}
                    type='button'
                    className='btn btn-primary btn-md nav-next'
                    onClick={onSubmitImages}
                  >
                    Update
                    <span
                      hidden={isLoadingImages.disabled - 1}
                      className='spinner-border spinner-border-sm ml-2'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </>
    )
  }

  return (
    <>
      <Wizard startIndex={tabStep}>
        <Step1 />
        <Step2 />
        <Step3 />
        <Step4 />
      </Wizard>
    </>
  )
}

export default AddProperty
